import type { LoadOptions } from '@rudderstack/analytics-js'
import { RudderAnalytics } from '@rudderstack/analytics-js'
import { ANONYMOUS_ID } from './constants'

const rudderAnalytics = new RudderAnalytics()

function initRudderAnalytics(loadOptions?: Partial<LoadOptions>) {
  rudderAnalytics.setAnonymousId(ANONYMOUS_ID)
  rudderAnalytics.load(
    process.env.RUDDERSTACK_PUBLIC_WRITE_KEY,
    'https://sayhelloyfposy.dataplane.rudderstack.com',
    {
      storage: { type: 'localStorage' },
      plugins: ['XhrQueue', 'StorageEncryption'],
      ...loadOptions,
    }
  )
}

export { rudderAnalytics, initRudderAnalytics }
