import { StorageKey } from 'kitchen/constants'

import { createId } from 'kitchen/utils/helpers'

export const SESSION_ID = getSessionId()
export const ANONYMOUS_ID = getAnonymousId()

/** @private Don't use outside module */
function getSessionId() {
  if (typeof window === 'undefined') {
    return 'SSR'
  }

  const id = window.sessionStorage.getItem(StorageKey.SESSION) ?? createId(36)
  window.sessionStorage.setItem(StorageKey.SESSION, id)

  return id
}

/** @private Don't use outside module */
function getAnonymousId() {
  if (typeof window === 'undefined') {
    return 'SSR'
  }
  const searchParams = new URLSearchParams(window.location.search)
  /**
   * present rudderId in URL means we've already generated it earlier.
   * (probably on another domain)
   */
  const rudderIdFromQuery = searchParams.get('rudderId')
  /**
   * if linkedin click ID is present, take it instead of generating a random one.
   */
  const linkedInIdFromQuery = searchParams.get('li_fat_id')

  return rudderIdFromQuery || linkedInIdFromQuery || getStoredAnonymousId()
}

/** @private Don't use outside module */
function getStoredAnonymousId() {
  if (typeof window === 'undefined') {
    return 'SSR'
  }

  const id = window.localStorage.getItem(StorageKey.ANONYMOUS_ID) ?? createId(36)
  window.localStorage.setItem(StorageKey.ANONYMOUS_ID, id)

  return id
}
