import type { Path as RouterPath } from 'react-router-dom'
import type { Join, PrefixAll } from '../types'
import { isObject } from '../utils/helpers'
export {
  parseAsString,
  parseAsStringLiteral,
  parseAsInteger,
  parseAsFloat,
  parseAsIsoDate,
  parseAsBoolean,
  parseAsArrayOf,
  parseAsJson,
} from 'nuqs'

export function match<I extends [...string[]]>(...input: I) {
  return input.join('/') as Join<I, '/'>
}

export function path<I extends [...string[]]>(...input: I) {
  return ('/' + input.join('/')) as Join<PrefixAll<I, '/'>>
}

export const isRouterPath = (input: unknown): input is RouterPath =>
  isObject(input) && 'pathname' in input
