import { theme } from '../stitches'

const base = {
  'headline-h1': {
    fontFamily: theme.fonts['headline-h1'],
    fontSize: theme.fontSizes['headline-h1'],
    fontWeight: theme.fontWeights['headline-h1'],
    fontVariationSettings: theme.fontVariations['headline-h1'],
    lineHeight: theme.lineHeights['headline-h1'],
    letterSpacing: theme.letterSpacings['headline-h1'],
    fontFeatureSettings: theme.fontFeatureSettings['headline-h1'],
  },
  'headline-h2': {
    fontFamily: theme.fonts['headline-h2'],
    fontSize: theme.fontSizes['headline-h2'],
    fontWeight: theme.fontWeights['headline-h2'],
    fontVariationSettings: theme.fontVariations['headline-h2'],
    lineHeight: theme.lineHeights['headline-h2'],
    fontFeatureSettings: theme.fontFeatureSettings['headline-h2'],
  },
  'headline-h3': {
    fontFamily: theme.fonts['headline-h3'],
    fontSize: theme.fontSizes['headline-h3'],
    fontWeight: theme.fontWeights['headline-h3'],
    fontVariationSettings: theme.fontVariations['headline-h3'],
    lineHeight: theme.lineHeights['headline-h3'],
    fontFeatureSettings: theme.fontFeatureSettings['headline-h3'],
  },
  'title-16': {
    fontFamily: theme.fonts['title-16'],
    fontSize: theme.fontSizes['title-16'],
    fontWeight: theme.fontWeights['title-16'],
    lineHeight: theme.lineHeights['title-16'],
    letterSpacing: theme.letterSpacings['title-16'],
  },
  'title-14': {
    fontFamily: theme.fonts['title-14'],
    fontSize: theme.fontSizes['title-14'],
    fontWeight: theme.fontWeights['title-14'],
    lineHeight: theme.lineHeights['title-14'],
    letterSpacing: theme.letterSpacings['title-14'],
  },
  'paragraph-20': {
    fontFamily: theme.fonts['paragraph-20'],
    fontSize: theme.fontSizes['paragraph-20'],
    fontWeight: theme.fontWeights['paragraph-20'],
    lineHeight: theme.lineHeights['paragraph-20'],
    letterSpacing: theme.letterSpacings['paragraph-20'],
  },
  'paragraph-16': {
    fontFamily: theme.fonts['paragraph-16'],
    fontSize: theme.fontSizes['paragraph-16'],
    fontWeight: theme.fontWeights['paragraph-16'],
    lineHeight: theme.lineHeights['paragraph-16'],
    letterSpacing: theme.letterSpacings['paragraph-16'],
  },
  'paragraph-14': {
    fontFamily: theme.fonts['paragraph-14'],
    fontSize: theme.fontSizes['paragraph-14'],
    fontWeight: theme.fontWeights['paragraph-14'],
    lineHeight: theme.lineHeights['paragraph-14'],
    letterSpacing: theme.letterSpacings['paragraph-14'],
  },
  'paragraph-12': {
    fontFamily: theme.fonts['paragraph-12'],
    fontSize: theme.fontSizes['paragraph-12'],
    fontWeight: theme.fontWeights['paragraph-12'],
    lineHeight: theme.lineHeights['paragraph-12'],
    letterSpacing: theme.letterSpacings['paragraph-12'],
  },
  'label-16': {
    fontFamily: theme.fonts['label-16'],
    fontSize: theme.fontSizes['label-16'],
    fontWeight: theme.fontWeights['label-16'],
    lineHeight: theme.lineHeights['label-16'],
    letterSpacing: theme.letterSpacings['label-16'],
  },
  'label-13': {
    fontFamily: theme.fonts['label-13'],
    fontSize: theme.fontSizes['label-13'],
    fontWeight: theme.fontWeights['label-13'],
    lineHeight: theme.lineHeights['label-13'],
    letterSpacing: theme.letterSpacings['label-13'],
    textTransform: theme.textTransforms['label-13'],
  },
} as const

const priceTag = {
  'price-tag-large-main': {
    fontFamily: theme.fonts['price-tag-large-main'],
    fontSize: theme.fontSizes['price-tag-large-main'],
    fontWeight: theme.fontWeights['price-tag-large-main'],
    fontVariationSettings: theme.fontVariations['price-tag-large-main'],
    lineHeight: theme.lineHeights['price-tag-large-main'],
    letterSpacing: theme.letterSpacings['price-tag-large-main'],
    fontFeatureSettings: theme.fontFeatureSettings['price-tag-large-main'],
  },
  'price-tag-large-support': {
    fontFamily: theme.fonts['price-tag-large-support'],
    fontSize: theme.fontSizes['price-tag-large-support'],
    fontWeight: theme.fontWeights['price-tag-large-support'],
    fontVariationSettings: theme.fontVariations['price-tag-large-support'],
    letterSpacing: theme.letterSpacings['price-tag-large-main'],
    lineHeight: theme.lineHeights['price-tag-large-support'],
    fontFeatureSettings: theme.fontFeatureSettings['price-tag-large-support'],
  },
  'price-tag-medium-main': {
    fontFamily: theme.fonts['price-tag-medium-main'],
    fontSize: theme.fontSizes['price-tag-medium-main'],
    fontWeight: theme.fontWeights['price-tag-medium-main'],
    fontVariationSettings: theme.fontVariations['price-tag-medium-main'],
    lineHeight: theme.lineHeights['price-tag-medium-main'],
    letterSpacing: theme.letterSpacings['price-tag-medium-main'],
    fontFeatureSettings: theme.fontFeatureSettings['price-tag-medium-main'],
  },
  'price-tag-medium-support': {
    fontFamily: theme.fonts['price-tag-medium-support'],
    fontSize: theme.fontSizes['price-tag-medium-support'],
    fontWeight: theme.fontWeights['price-tag-medium-support'],
    fontVariationSettings: theme.fontVariations['price-tag-medium-support'],
    lineHeight: theme.lineHeights['price-tag-medium-support'],
    letterSpacing: theme.letterSpacings['price-tag-medium-support'],
    fontFeatureSettings: theme.fontFeatureSettings['price-tag-medium-support'],
  },
  'price-tag-small-main': {
    fontFamily: theme.fonts['price-tag-small-main'],
    fontSize: theme.fontSizes['price-tag-small-main'],
    fontWeight: theme.fontWeights['price-tag-small-main'],
    fontVariationSettings: theme.fontVariations['price-tag-small-main'],
    lineHeight: theme.lineHeights['price-tag-small-main'],
    letterSpacing: theme.letterSpacings['price-tag-small-main'],
    fontFeatureSettings: theme.fontFeatureSettings['price-tag-small-main'],
  },
  'price-tag-small-support': {
    fontFamily: theme.fonts['price-tag-small-support'],
    fontSize: theme.fontSizes['price-tag-small-support'],
    fontWeight: theme.fontWeights['price-tag-small-support'],
    fontVariationSettings: theme.fontVariations['price-tag-small-support'],
    lineHeight: theme.lineHeights['price-tag-small-support'],
    letterSpacing: theme.letterSpacings['price-tag-small-support'],
    fontFeatureSettings: theme.fontFeatureSettings['price-tag-small-support'],
  },
} as const

const semantic = {
  'button-large': {
    fontFamily: theme.fonts['button-large'],
    fontSize: theme.fontSizes['button-large'],
    fontWeight: theme.fontWeights['button-large'],
    fontVariationSettings: theme.fontVariations['button-large'],
    lineHeight: theme.lineHeights['button-large'],
    fontFeatureSettings: theme.fontFeatureSettings['button-large'],
    letterSpacing: theme.letterSpacings['button-large'],
    textTransform: theme.textTransforms['button-large'],
  },
  'button-medium': { ...base['title-16'] },
  'button-small': { ...base['title-14'] },
  'avatar-large': {
    fontFamily: theme.fonts['avatar'],
    fontFeatureSettings: theme.fontFeatureSettings['headline-h2'],
    fontSize: theme.fontSizes['avatar-large'],
    lineHeight: theme.lineHeights['avatar-large'],
    letterSpacing: theme.letterSpacings['avatar'],
    fontWeight: theme.fontWeights['avatar'],
    textTransform: theme.textTransforms['avatar'],
  },
  'avatar-medium': {
    fontFamily: theme.fonts['avatar'],
    fontFeatureSettings: theme.fontFeatureSettings['headline-h2'],
    fontSize: theme.fontSizes['avatar-medium'],
    lineHeight: theme.lineHeights['avatar-medium'],
    letterSpacing: theme.letterSpacings['avatar'],
    fontWeight: theme.fontWeights['avatar'],
    textTransform: theme.textTransforms['avatar'],
  },
  'avatar-small': {
    fontFamily: theme.fonts['avatar'],
    fontFeatureSettings: theme.fontFeatureSettings['headline-h2'],
    fontSize: theme.fontSizes['avatar-small'],
    lineHeight: theme.lineHeights['avatar-small'],
    letterSpacing: theme.letterSpacings['avatar'],
    fontWeight: theme.fontWeights['avatar'],
    textTransform: theme.textTransforms['avatar'],
  },
} as const

const website = {
  'website-fluid-130': {
    // https://modern-fluid-typography.vercel.app?rootFontSize=16&minSize=52&fluidSize=8.263&relativeSize=0.75&maxSize=130
    '--website-fluid-130': 'clamp(3.25rem, 8.263vw + 0.75rem, 8.125rem)',
    fontFamily: theme.fonts.champ,
    fontWeight: 800,
    fontSize: '48px',
    letterSpacing: '0.52px',
    fontStyle: 'normal',
    lineHeight: '95%',
    '@bp1': {
      fontSize: 'var(--website-fluid-130)',
      letterSpacing: 'calc(var(--website-fluid-130) / 100)',
    },
    '@bp4': {
      fontSize: '130px',
      letterSpacing: '1.3px',
    },
  },
  'website-fluid-140': {
    // https://modern-fluid-typography.vercel.app?rootFontSize=16&minSize=52&fluidSize=10&relativeSize=0.25&maxSize=140
    '--website-fluid-140': 'clamp(3.25rem, 10vw + 0.25rem, 8.75rem)',
    fontFamily: theme.fonts.champ,
    fontWeight: 800,
    fontSize: '34px',
    fontStyle: 'normal',
    lineHeight: '95%',
    letterSpacing: '0.52px',
    '@bp1': {
      fontSize: 'var(--website-fluid-140)',
      letterSpacing: 'calc(var(--website-fluid-140) / 100)',
    },
    '@bp4': {
      fontSize: '140px',
      letterSpacing: '1.4px',
    },
  },
  'website-promo-head': {
    fontFamily: theme.fonts.champ,
    fontWeight: 800,
    fontSize: '48px',
    fontStyle: 'normal',
    lineHeight: '100%',
    letterSpacing: '0.48px',
    '@bp2': {
      fontSize: '64px',
      letterSpacing: '0.64px',
    },
  },
  'website-h2': {
    fontFamily: theme.fonts.champ,
    fontWeight: 800,
    fontSize: '32px',
    letterSpacing: '0.32px',
    fontStyle: 'normal',
    lineHeight: '100%',
    '@bp2': { fontSize: '64px', letterSpacing: '0.64px' },
  },
  'website-h3': {
    fontFamily: theme.fonts.champ,
    fontWeight: 500,
    fontSize: '24px',
    fontStyle: 'normal',
    lineHeight: '120%',
    letterSpacing: '0.24px',
    '@bp2': { fontSize: '34px', letterSpacing: '0.34px' },
  },
  'website-h4': {
    fontFamily: theme.fonts.champ,
    fontWeight: 800,
    fontSize: '24px',
    fontStyle: 'normal',
    lineHeight: '120%',
    letterSpacing: '0.24px',
  },
  'website-h5': {
    fontFamily: theme.fonts.dmmono,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '18px',
    letterSpacing: '-0.18px',
    lineHeight: '150%',
    fontFeatureSettings: "'ss09' on, 'ss11' on, 'ss06' on",
    '@bp2': { fontSize: '24px', letterSpacing: '-0.24px' },
  },
  'website-h6': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '20px',
    letterSpacing: '-0.20px',
    lineHeight: 'normal',
    fontFeatureSettings: "'ss09' on, 'ss11' on, 'ss06' on",
  },
  // "1000" in 1000s
  'website-spotlight-main': {
    fontFamily: theme.fonts.champ,
    fontWeight: 800,
    fontSize: '100px',
    fontStyle: 'normal',
    lineHeight: '90%',
    letterSpacing: '2px',
    '@bp2': { fontSize: '240px', letterSpacing: '4.8px', lineHeight: '100%' },
  },
  // "s" in 1000s
  'website-spotlight-support': {
    fontFamily: theme.fonts.champ,
    fontWeight: 800,
    fontSize: '28px',
    fontStyle: 'normal',
    letterSpacing: '0.56px',
    lineHeight: '90%',
    '@bp2': { fontSize: '120px', letterSpacing: '2.4px', lineHeight: '100%' },
  },
  'website-body-64': {
    fontFamily: theme.fonts.champ,
    fontWeight: 500,
    fontSize: '52px',
    fontStyle: 'normal',
    letterSpacing: '1.04px',
    lineHeight: '100%',
    '@bp2': { fontSize: '64px', letterSpacing: '1.28px' },
  },
  'website-body-52': {
    fontFamily: theme.fonts.champ,
    fontWeight: 800,
    fontSize: '28px',
    fontStyle: 'normal',
    letterSpacing: '0.56px',
    lineHeight: '124%',
    '@bp2': { fontSize: '52px', letterSpacing: '-0.52px', lineHeight: '110%' },
  },
  'website-body-46': {
    fontFamily: theme.fonts.champ,
    fontWeight: 500,
    fontSize: '28px',
    fontStyle: 'normal',
    letterSpacing: '0.56px',
    lineHeight: '124%',
    '@bp2': { fontSize: '46px', letterSpacing: '-0.46px', lineHeight: '138%' },
  },
  'website-body-32': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 400,
    fontSize: '26px',
    letterSpacing: '-0.26px',
    fontStyle: 'normal',
    lineHeight: '120%',
    '@bp2': { fontSize: '32px', letterSpacing: '0.32px' },
  },
  'website-body-24': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: '-0.18px',
    fontStyle: 'normal',
    lineHeight: '120%',
    '@bp2': { fontSize: '24px', letterSpacing: '0.24px' },
  },
  'website-body-22': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: '-0.18px',
    fontStyle: 'normal',
    lineHeight: '120%',
    '@bp2': { fontSize: '22px', lineHeight: '136%', letterSpacing: '0.22px' },
  },
  'website-body-20': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '26px',
    letterSpacing: '-0.01em',
    fontFeatureSettings: "'ss09' on, 'ss11' on, 'ss06' on",
  },
  'website-body-16': {
    fontFamily: theme.fonts.dmmono,
    fontWeight: 400,
    fontSize: '16px',
    fontStyle: 'normal',
    letterSpacing: '0.16px',
    lineHeight: '145%',
  },
  'website-body-14': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 400,
    fontSize: '14px',
    fontStyle: 'normal',
    letterSpacing: '0.14px',
    lineHeight: '100%',
  },
  'website-switch': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 700,
    fontSize: '12px',
    letterSpacing: '-0.12px',
    fontStyle: 'normal',
    lineHeight: '150%',
    '@bp2': { fontSize: '24px', letterSpacing: '0.24px' },
  },
  'website-menu': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 500,
    fontSize: '16px',
    fontStyle: 'normal',
    letterSpacing: '-0.16px',
    lineHeight: '130%',
    fontFeatureSettings: "'ss09' on, 'ss11' on, 'ss06' on",
  },
  'website-button': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 700,
    fontSize: '18px',
    fontStyle: 'normal',
    letterSpacing: '-0.18px',
    lineHeight: '150%',
    fontFeatureSettings: "'ss09' on, 'ss11' on, 'ss06' on",
    '@bp2': { fontSize: '24px', letterSpacing: '-0.24px' },
  },
  'website-small-button-highlight': {
    fontFamily: theme.fonts.dmmono,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '14px',
    letterSpacing: '-0.14px',
    lineHeight: '150%',
    fontFeatureSettings: "'ss09' on, 'ss11' on, 'ss06' on",
    '@bp2': { fontSize: '20px', letterSpacing: '-0.20px' },
  },
  'website-label': {
    fontFamily: theme.fonts.dmmono,
    fontWeight: 400,
    fontSize: '16px',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: '70%',
  },
  'website-faq-header': {
    fontFamily: theme.fonts.champ,
    fontWeight: 500,
    fontSize: '18px',
    fontStyle: 'normal',
    letterSpacing: '-0.18px',
    lineHeight: '19.8px',
    '@bp2': { fontSize: '46px', letterSpacing: '-0.46px', lineHeight: '63.48px' },
  },
  'website-footer': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 400,
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '24px',
    fontFeatureSettings: "'ss09' on",
  },
  'website-section-label': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    fontFeatureSettings: "'ss09' on, 'ss11' on, 'ss06' on",
  },
  'website-feature-disclaimer': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    fontFeatureSettings: "'ss09' on",
    letterSpacing: '-0.01em',
    '@bp3': { fontSize: '16px', lineHeight: '21px' },
  },
  'website-feature-label': {
    fontFamily: theme.fonts.dmsans,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    letterSpacing: '-0.01em',
    fontFeatureSettings: "'ss09' on, 'ss11' on, 'ss06' on",
    '@bp3': { fontSize: '20px', lineHeight: '26px' },
  },
  'website-review-quote': {
    fontFamily: theme.fonts.champ,
    fontFeatureSettings: "'ss09' on",
    fontWeight: 500,
    fontSize: '28px',
    fontStyle: 'normal',
    lineHeight: '124%',
    letterSpacing: '0.56px',
    '@bp3': { fontSize: '34px', letterSpacing: '-0.34px', lineHeight: '138%' },
  },
  'website-review-author': {
    fontFamily: theme.fonts.dmsans,
    fontFeatureSettings: "'ss09' on",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '138%',
    letterSpacing: '-0.14px',
    '@bp3': {
      fontSize: '22px',
      letterSpacing: '-0.22px',
    },
  },
  'website-getpaid-cta-headline': {
    fontFamily: theme.fonts.dmsans,
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 700,
  },
} as const

export const typography = {
  ...base,
  ...priceTag,
  ...semantic,
  ...website,
} as const

export type Typography = keyof typeof typography
