import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { User } from 'api/types/users'
import { CacheKey } from 'kitchen/constants'
import type { MutationHookFactory } from 'kitchen/types'
import { publicApi } from 'kitchen/utils/api'
import { useDebouncedRequest } from 'kitchen/utils/fetch'
import { ANONYMOUS_ID } from './constants'
import { sendAnalyticsEvent } from './requests'
import { rudderAnalytics } from './rudderstack'
import type { SendAnalyticsEventPayload } from './types'

export const useSendAnalyticsEvent: MutationHookFactory<
  SendAnalyticsEventPayload,
  void
> = (options) => {
  const queryClient = useQueryClient()
  const debouncedRequest = useDebouncedRequest()

  return useMutation(async (payload) => {
    const currentUser = queryClient.getQueryData<User>([CacheKey.CURRENT_USER])

    if (process.env.BUILD_MODE === 'development' || process.env.PLAYWRIGHT) {
      console.debug('frontend-event', payload)
      return
    }
    const controller = new AbortController()

    return debouncedRequest(
      () =>
        sendAnalyticsEvent(publicApi, {
          userId: currentUser?.id,
          anonymousId: ANONYMOUS_ID,
          ...payload,
        }),
      controller.signal
    )
  }, options)
}

export const useSendRudderStackAnalyticsEvent: MutationHookFactory<
  SendAnalyticsEventPayload,
  void
> = (options) => {
  return useMutation(async (payload) => {
    if (process.env.BUILD_MODE !== 'production' || process.env.PLAYWRIGHT) {
      return
    }

    return rudderAnalytics.track(payload.event, {
      ...payload.data,
      subjectId: payload.subjectId,
      companyId: payload.companyId,
    })
  }, options)
}
