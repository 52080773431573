import { useSendAnalyticsEvent } from 'domains/analytics/queries'
import { createCancellablePromise } from 'kitchen/utils/async'
import { useEffect } from 'react'

export function useTrackPageVisit(
  pathname: string,
  eventName: 'PAGE_VISIT' | 'GET_PAID_PAGE_VISIT' = 'PAGE_VISIT'
) {
  const { mutateAsync: sendAnalyticsEvent } = useSendAnalyticsEvent()

  useEffect(() => {
    const promise = createCancellablePromise(() =>
      sendAnalyticsEvent({
        event: eventName,
        data: { pathname },
      })
    )

    return () => {
      promise.abort('Cancelled')
    }
  }, [sendAnalyticsEvent, pathname, eventName])
}
