import type { GenericComponent } from 'kitchen/types'
import { Text } from '../../primitives'
import { styled, theme } from '../../stitches'
import { gap } from '../../variants'

const ListBase = styled(Text, {
  display: 'grid',
  gridAutoFlow: 'row',
  variants: {
    type: {
      bullet: {
        listStyleType: 'disc',
      },
      decimal: {
        listStyleType: 'decimal',
      },
    },
    ...gap,
  },
  paddingLeft: theme.space[24],
  defaultVariants: {
    type: 'bullet',
  },
})

interface ListProps extends React.ComponentPropsWithoutRef<typeof ListBase> {}

const List: GenericComponent<ListProps> = ({ type, children, ...rest }: ListProps) => (
  <ListBase as={type === 'decimal' ? 'ol' : 'ul'} type={type} {...rest}>
    {children}
  </ListBase>
)

const ListItem = styled('li', {
  marginTop: theme.space[2],
})

export { List as Root, ListItem as Item }
